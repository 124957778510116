<template>
  <div class="rumicenter">
    <section class="wrapper image-wrapper bg-image bg-overlay bg-overlay-400 text-white px-0 mt-0" :style="{ backgroundImage: 'url(' + require('../../public/assets/i/pageTitle.jpg') + ')' }">
      <div class="container pt-17 pb-20 pt-md-19 pb-md-21 text-center">
        <div class="row">
          <div class="col-lg-8 mx-auto">
            <div class="post-header">
              <h1 class="display-1 mb-3 white">{{ product.title }}</h1>
              <nav class="d-inline-block" aria-label="breadcrumb">
                <ol class="breadcrumb text-white">
                  <li class="breadcrumb-item"><router-link to="/">{{ langcontent.home }}</router-link></li>
                  <li class="breadcrumb-item active" aria-current="page" >{{ product.title }}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="wrapper bg-light wrapper-border">
      <div class="container pb-14 pb-md-16">
        <div class="row">
          <div class="col-12">
            <article class="mt-n21">
              <figure class="rounded mb-8 mb-md-12">
                <img :src="product.img" :alt="product.title"/>
              </figure>
              <div class="row">
                <div class="col-lg-12">
                  <h2 class="display-6 mb-4">{{ product.title }}</h2>
                  <div class="row gx-0">
                    <div v-if="product.d0title" class="col-md-4">
                      <h5 class="mb-1">{{ product.d0title }}</h5>
                      <p v-html="product.desc0">
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<style>
.white{
  color: white!important;
}
.row.gx-0 .col-md-4{
  padding: 0 20px 10px 20px;
}
.display-6{
  padding-left: 20px;
}
</style>
<script>
import iso from "../axios";

export default {
  name: 'Cat',
  data() {
    return {
      cat: '',
      langcontent: [],
      product: [],
    }
  },
  methods: {
    pGet() {
      iso.get('langfix')
          .then(response => {
            this.langcontent = response.data[0]
          })
          .catch(error => {
            console.log(error);
          })
      iso.get('products/'+this.$route.params.rumi)
          .then(response => {
            this.product = response.data
            document.title = 'Bimark | '+this.product.title;
          })
          .catch(error => {
            console.log(error);
          })
    },
  },
  mounted() {
    this.pGet()
  },
  watch: {
    $route(to, from) {
      this.pGet()
    }
  },
  beforeRouteLeave(to, from, next) {
    next();
    window.scrollTo(0, 0);
  },
  beforeRouteEnter(to, from, next) {
    next();
    window.scrollTo(0, 0);
  }
};
</script>